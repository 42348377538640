
$color-darkgray: #333;
$color-medgray: #555;
$color-lightgray: #aaa;
$color-lightestgray: #ddd;

$color-orange: #FF9900;
$color-lightorange: #FFCC00;

$color-blue: #3690ff;
$color-lightblue: #85caef;
$color-lightestblue: #bce2f7;
$color-darkblue: #003366;

$color-green: #92ce8b;
$color-lightgreen: #d3ebd1;

$color-red: #e84040;
$color-lightred: #ea7171;
