/* You can add global styles to this file, and also import other style files */
@import './assets/colors.scss';

body {
  font-family: "Source Sans Pro", sans-serif;
}

h1 {
  font-size: 3em;
  color: $color-darkgray;
}

h2 {
  font-size: 2em;
  color: $color-darkgray;
}

h3 {
  font-size: 1.8em;
  color: $color-medgray;
  margin-bottom: 25px;
}

h4 {
  font-size: 1.3em;
  color: $color-medgray;
  margin-bottom: 5px;
}

a {
  color: $color-orange;
  cursor: pointer;
}

p {
  margin: 10px 0;
}

header {
  margin: 15px 0 25px;
}

#page-title {
  display: inline-block;
  vertical-align: top;
  margin: 5px 0 0 30px;
}

.container {
  max-width: 1200px;
  min-width: 800px;
  margin: 0 auto;
  padding: 0 5px;
  clear: both;
}

#logo-contain {
  display: inline-block;
}

#logo {
  margin: 0 5px 0 0;
  width: 170px;
  height: 60px;
}

#login {
  float: right;
  margin-top: 28px;
}

#login .button {
  margin-left: 10px;
}

pv-modal {
  display: none;
}

.alert, .message {
  width: 100%;
  color: #e23333;
  background-color: #fbe1e1;
  padding: 5px 0;
  border-radius: 5px;
  text-align: center;
}

.message {
  color: #009900;
  background-color: #e0f1e0;
  text-align: left;
  margin: 5px 0;
}

.message p, .alert p {
  padding: 5px 10px;
}


.table-contain {
  margin-top: 15px;
}

mat-hint {
    color: #e48900;
}

.mat-mdc-form-field-focus-overlay, .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: unset;
}

mat-form-field .mdc-text-field {
    padding: 0;
}

.mat-mdc-form-field, .mat-mdc-floating-label {
    letter-spacing: unset;
}

.mat-mdc-radio-button.mat-accent {
    --mdc-radio-selected-focus-icon-color: #40b8ff;
    --mdc-radio-selected-hover-icon-color: #40b8ff;
    --mdc-radio-selected-icon-color: #40b8ff;
    --mdc-radio-selected-pressed-icon-color: #40b8ff;
    --mat-mdc-radio-checked-ripple-color: #40b8ff;
}

.mat-mdc-checkbox.mat-accent {
    --mdc-checkbox-selected-focus-icon-color: #40b8ff;
    --mdc-checkbox-selected-hover-icon-color: #40b8ff;
    --mdc-checkbox-selected-icon-color: #40b8ff;
    --mdc-checkbox-selected-pressed-icon-color: #40b8ff;
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
mat-table, .mdc-data-table__table, mat-table.mdc-data-table__table {
    margin: 0px auto;
    width: 100%;
    border: solid 1px #8bcfef;
}

.mat-mdc-table tbody, .mat-mdc-table tfoot, .mat-mdc-table thead, .mat-mdc-cell, .mat-mdc-footer-cell, .mat-mdc-header-row, .mat-mdc-row, .mat-mdc-footer-row, .mat-mdc-table .mat-mdc-header-cell {
    background: unset;
}

mat-header-row {
    color: #fff;
    text-align: left;
    background-color: #0096e5 !important;
}

mat-header-cell {
    color: #FFF !important;
}

mat-table .mdc-data-table__cell, mat-table .mdc-data-table__header-cell {
    padding: unset;
}

mat-cell.mat-mdc-cell {
    padding: 0 2px;
    color: inherit;
}


mat-cell:first-of-type, mat-footer-cell:first-of-type, mat-header-cell:first-of-type {
    padding-left: unset;
}

mat-cell:last-of-type, mat-footer-cell:last-of-type, mat-header-cell:last-of-type {
    padding-right: 10px;
}

mat-table mat-row, mat-table mat-header-row {
    min-height: 40px;
}

.mat-mdc-header-cell {
    font-size: unset;
    color: unset;
}

/*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
mat-table .mat-mdc-select-value {
    color: #fff;
}

mat-cell div p {
    white-space: nowrap;
}

mat-row.local-appointment {
    color: #009900;
}

.mat-mdc-form-field.filter {
    display: block;
    width: 100%;
}

mat-header-row th.mat-mdc-header-cell {
    border-bottom-width: 0;
}

.mdc-text-field--filled, .mdc-text-field--focused, mdc-text-field--disabled, .mat-focused .mdc-text-field .mdc-text-field__input {
    background-color: unset !important;
}

input:-internal-autofill-selected {
    background-color: unset !important;
}

.mat-mdc-select-panel {
    background: #fff;
}

.mat-mdc-form-field-no-padding .mat-mdc-form-field-wrapper {
    margin-bottom: -0.5em;
}

.mat-mdc-form-field-appearance-outline .mat-mdc-form-field-infix {
    padding: 0 0 .5em 0;
}

.faux-mat-form-field > label > span:not(.mat-mdc-checkbox-label), .form-list-item > label > span:not(.mat-mdc-checkbox-label) {
    margin-left: 8px;
}

.form-list-item {
    margin: -6px 0;
}

.mat-mdc-select-panel {
    background: #fff;
}

.clickable-row:hover, mat-row.mat-mdc-row.mdc-data-table__row.cdk-row.clickable-row:hover {
    cursor: pointer;
    background-color: #cee9f7 !important; /*#009CE5;*/
}

.even-row, mat-row.mat-mdc-row.mdc-data-table__row.cdk-row.even-row {
    background-color: #FFF !important;
}

.odd-row, mat-row.mat-mdc-row.mdc-data-table__row.cdk-row.odd-row {
    background-color: #f3f7f9 !important;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding-top: 12px;
    padding-bottom: 12px;
}

.mat-mdc-form-field-infix {
    min-height: unset; 
}



.full-width {
  width: 100%;
}

.half-width {
  width: 48%;
}

.half-width.first-half-option {
    padding-right: 4%;
}

.half-width.second-half-option {
    padding-left: 4%;
}

.half-width-fixed {
  width: 570px;
}

.half-width-fixed > div {
  margin: 10px 0;
}

.third-width {
    width: 32%;
}
.third-width.middle-option {
    padding: 0 2%;
}

#information-container div .third-width:first-of-type {
  padding-left: 0;
}

.state-short {
  margin-right: 20px;
  width: 30%;
}

.checkbox-contain {
  padding: 20px 0 10px;
  display: inline-block;
  color: $color-medgray;
}

.button {
  background-color: $color-orange; /*#003366;*/
  color: #FFF;
  padding: 5px 15px;
  cursor: pointer;
  border: 0;
}

.button:hover {
  background-color: $color-lightorange; /*#6294c7;*/ /*#E2F4FD;*/
}

.button.disabled {
  background-color: $color-lightgray; /* #AFAFAF;*/
  cursor: not-allowed;
}

.page-next {
  margin: 40px 0 60px;
  padding: 10px 50px;
}

#launch-docusign {
  margin: 30px 0;
  display: inline-block;
}

.finalize-button {
  margin: 60px 0 60px;
  padding: 15px 60px;
}

.warning-btn {
  background-color: $color-red;
}

.warning-btn:hover {
  background-color: $color-lightred;
}


.external-link {
  text-decoration: underline;
  color: $color-blue;
}
.external-link:hover {
  color: $color-orange;
}

.state-code {
  width: 50px;
}
/*.button.disabled:hover {
  background-color: #AFAFAF;
}*/
/*.elevated-btn {
  background-color: $color-red;
}

.elevated-btn:hover {
  background-color: #91de4b;
}*/
.hidden {
  display: none;
}

.middle {
  vertical-align: middle;
}

.top {
  vertical-align: top;
}

.inline {
  display: inline-block;
}

.bold {
  font-weight: bold;
}

.med-gray {
  color: $color-medgray;
}
.underline {
    text-decoration: underline;
}
.proper-name, .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control.proper-name {
    text-transform: capitalize;
}

.float-right {
  float: right;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.center {
  text-align: center;
}

.italics {
  font-style: italic;
}
/*******LOADING SPINNER STYLES********/
#loading-container {
  /*position: absolute;*/
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 9999999;
  /*background-color: RGBA(128,128,128,.5);*/
  /*padding-top: 20%;*/
  /*display: none;*/
}

.loading-spinner {
  color: #73c0e8;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  5%, 95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  10%, 59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }

  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }

  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }

  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  5%, 95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  10%, 59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }

  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }

  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }

  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

html, body {
  /*height: 100%;*/
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
